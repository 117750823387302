/* Full container with background image */
.forgot-container {
    height: 100vh; 
    width: 100%;
    background-color: #0D868C;
    background-size: cover; 
    background-position: center; 
    position: relative; 
  }
  
  /* Overlay layer */
  .overlay {
    height: 100%; 
    width: 100%; 
    background-image: url('../../Asset/bg-layer.png'); 
    background-size: cover; 
    background-position: center;
    position: absolute; 
    top: 0;
    left: 0;
    display: flex; 
    justify-content: center;
    align-items: center;
  }
  