.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .page-num {
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid #ddd;
    cursor: pointer;
    color: #0D868C;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .page-num:hover {
    background-color: #0D868C;
    color: white;
    border: none;
  }
  
  .activePage {
    background-color: #0D868C;
    color: white;
    border: none;
  }
  