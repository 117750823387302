@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-inter;
}
html, body {
  height: 100%; 
  margin: 0;
  padding: 0;
}

@layer base { img { display: initial; } }
