/* Outer container */
.outer-container {
  height: 100%;
  width: 100%;
  background-image: url("./Asset/bg-color.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  top: 0;
  z-index: 0;
}

/* Inner container */
.inner-container {
  /* min-height: 100%;
  width: 100%; */
  background-image: url("./Asset/bg-layer-white.png");
  background-size: cover;
  background-position: center;
  /* position: absolute; */
  /* top: 0; */
  left: 0;
  /* display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; */
  z-index: 1;
}

/* global.css */
.scrollbar-hide {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.transition-max-height {
  transition: max-height .6s ease-in-out;
  overflow: hidden;
}

